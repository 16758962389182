import React, { useState, useRef, useEffect, useCallback } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import useMediaQuery from '../hooks/use-media-query'
import Seo from '../components/Seo'

const UpcomingCoursesPage = ({ data }) => {
  const [upcomingCourses, setUpcomingCourses] = useState([])
  const learningMethodInputRef = useRef(null)

  const mediaQueryMatches = useMediaQuery('(max-width: 768px)')

  const courses = data.allSanityCourse.nodes
  const learningMethods = data.allSanityLearningMethod.nodes

  const learningMethodOptionElements = learningMethods.map(({title, slug}) => (
    <option key={slug.current} value={slug.current}>{title}</option>
  ))

  const filterUpcomingCourses = useCallback(() => {
    const learningMethodInputValue = learningMethodInputRef.current.value

    const upcomingCoursesArr = courses.filter(course => {
      const learningMethods = course.courseStartDates.map(startDate => startDate.learningMethod.slug.current)

      if (learningMethods.includes(learningMethodInputValue)) {
        const startDate = course.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputValue).startDate

        return new Date(startDate) > new Date()
      } else {
        return false
      }
    })

    // Sort by course start date of current learning method 
    // the graphql sort doesn't do it perfectly bc {courseStartDates} is an array
    upcomingCoursesArr.sort((courseA, courseB) => {
      const courseAStartDate = courseA.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputValue).startDate
      const courseBStartDate = courseB.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputValue).startDate
      
      return new Date(courseAStartDate) - new Date(courseBStartDate)
    })

    setUpcomingCourses(upcomingCoursesArr)
  }, [courses])

  const handleSubmit = (event) => {
    event.preventDefault()
    filterUpcomingCourses()
  }

  useEffect(() => {
    filterUpcomingCourses()
  }, [filterUpcomingCourses])

  const coursesTableRowElements = upcomingCourses.map(( course ) => {
    const {
      learningMethod,
      classTimes,
      duration,
      startDate
    } = course.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputRef.current.value)

    if (mediaQueryMatches) {
      return (
        <tr key={course.slug.current}>
          <td><Link to={`/courses/${course?.slug?.current}/`}>
            {course.title}
          </Link></td>
          <td><Link to={`/courses/${course?.slug?.current}/`}>
            {new Date(startDate).toLocaleDateString('en-ZA', {day: 'numeric', month: 'short', year: 'numeric'})}
          </Link></td>
          <td className='tt-lowercase'><Link to={`/courses/${course?.slug?.current}/`}>
            {duration}
          </Link></td>
          {/* <td><Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}`}>
            {classTimes}
          </Link></td> */}
          {/* <td><Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}`}>
            {learningMethod.title.replace(' Courses', '')}
          </Link></td> */}
        </tr>
      )
    }

    return (
      <tr key={course.slug.current}>
        <td><Link to={`/courses/${course?.slug?.current}/`}>
          {course.title}
        </Link></td>
        <td className='tt-lowercase'><Link to={`/courses/${course?.slug?.current}/`}>
          {duration}
        </Link></td>
        <td><Link to={`/courses/${course?.slug?.current}/`}>
          {new Date(startDate).toLocaleDateString('en-ZA', {day: 'numeric', month: 'short', year: 'numeric'})}
        </Link></td>
        <td><Link to={`/courses/${course?.slug?.current}/`}>
          {classTimes}
        </Link></td>
        <td><Link to={`/courses/${course?.slug?.current}/`}>
          {learningMethod.title.replace(' Courses', '')}
        </Link></td>
      </tr>
    )
  })

  return (
    <StyledUpcomingCoursesPage>
      <Seo 
        title='Upcoming Courses' 
        pathname='/upcoming-courses'
      />
      <h1>Upcoming Courses</h1>

      <p className="breadcrumbs">
        <Link to='/'>Home</Link> {`>`}&nbsp;
        Upcoming Courses
      </p>

      <form onSubmit={handleSubmit}>
        <h3>Choose a Learning Method</h3>
        <select name="" id="" ref={learningMethodInputRef} onChange={handleSubmit} defaultValue="part-time-courses">
          {learningMethodOptionElements}
        </select>
        {/* <button type="submit">Search</button> */}
      </form>

      <div className='table-container'>
        <table>
          <thead>
            <tr>
              {mediaQueryMatches ?
                (<>
                  <th>Course</th>
                  <th>Start Date</th>
                  <th>Duration</th>
                </>) :
                (<>
                  <th>Course</th>
                  <th>Duration</th>
                  <th>Start Date</th>
                  <th>Class Times</th>
                  <th>Learning Method</th>
                </>)
              }
            </tr>
          </thead>
          <tbody>
            {coursesTableRowElements}
          </tbody>
        </table>
      </div>

      {/* <ul className="upcoming-courses-list">
        {upcomingCourses.map(course => {
          const startDate = course.courseStartDates.find(instance => instance.learningMethod.slug.current === learningMethodInputRef.current.value).startDate

          return <li key={course.slug.current}>
            <Link to={`/${course.studyFields[0].slug.current}/${course.slug.current}`}>
              <span className='title'>{course.title}</span>
              <span className='date'>{new Date(startDate).toLocaleDateString(undefined, {day: 'numeric', month: 'short', year: 'numeric'})}</span>
            </Link>
          </li>
        })}
      </ul> */}
    </StyledUpcomingCoursesPage>
  )
}

export const query = graphql`
  query UpcomingCoursesPageQuery {
    allSanityCourse(sort: {fields: [courseStartDates___startDate, title]}) {
      nodes {
        title
        slug { current }
        studyFields {
          slug { current }
        }
        courseStartDates {
          learningMethod {
            title
            slug { current }
          }
          classTimes
          duration
          startDate
        }
      }
    }
    allSanityLearningMethod(sort: {fields: rank}) {
      nodes {
        title
        slug {
          current
        }
      }
    }
  }
`

const StyledUpcomingCoursesPage = styled.main`
  margin: auto;
  padding: 6rem 4rem 4rem 4rem;
  max-width: 1500px;

  .tt-lowercase {
    text-transform: lowercase;
  }

  form {
    max-width: 300px;
    margin-bottom: 2rem;

    h3 {
      color: var(--primary-color);
      margin-bottom: 0.8rem;
      font-weight: 600;
    }

    select {
      display: block;
      color: var(--primary-color); // #999
      width: 100%;
      padding: 0.8em;
      font-size: 1.1rem;
      font-weight: 400;
      font-family: 'Montserrat';
      border: solid 1px var(--primary-color); // #ddd

      :focus, &:active {
        outline-color: var(--primary-color);
      }
    }

    button[type='submit'] {
      flex: 1 0 auto;
      background: var(--primary-color);
      color: #f4f4f4;
      height: fit-content;
      padding: 0.9em 1.1em;
      border: none;
      font-size: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }

  div.table-container {
    overflow: scroll;
  }

  table {
    --cell-padding: 0.6rem;
    width: 100%;
    min-width: 800px;
    border-spacing: 0;
    overflow: visible;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: var(--cell-padding);
      text-decoration: none;
    }

    thead {
      color: var(--primary-color);
      text-align: left;
      text-transform: uppercase;

      th {
        padding: var(--cell-padding);
      }
    }

    tr {
      &:nth-child(even) {
        background-color: #f8f4f4;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 4rem 1rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 0.5rem;
    
    div.table-container {
      overflow: initial;
      /* width: 100%; */
    }

    table {
      --cell-padding: 0.4rem 0rem 0.4rem 0.3rem;
      min-width: initial;
      font-size: 0.85rem;
    }
  }
`

export default UpcomingCoursesPage
